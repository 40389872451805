<template>
    <div>
      <button @click="playSound">Play Sound</button>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      playSound() {
        // Maak een nieuwe AudioContext aan
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        
        // Definieer parameters voor de sinusgolf
        const frequency = 140; // Frequentie van de sinusgolf in Hz
        const duration = 1; // Duur van de sinusgolf in seconden
        const sampleRate = audioContext.sampleRate;
        const bufferSize = duration * sampleRate;
        
        // Maak een buffer aan om de golfvorm op te slaan
        const buffer = audioContext.createBuffer(1, bufferSize, sampleRate);
        const channelData = buffer.getChannelData(0);
        
        // Genereer de sinusgolf met een beetje willekeurige ruis
        for (let i = 0; i < bufferSize; i++) {
          const t = i / sampleRate;
          const value = Math.sin(2 * Math.PI * frequency * t); // Sinusgolfwaarde
          
          // Voeg een beetje willekeurige ruis toe (tussen -0.1 en 0.1)
          const noise = (Math.random() - 0.5) * 0.2;
          
          channelData[i] = value + noise;
        }
        
        // Creëer een AudioBufferSourceNode om de buffer af te spelen
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        
        // Sluit de source node aan op de uitvoer
        source.connect(audioContext.destination);
        
        // Start de afspeelbron
        source.start();
      }
    }
  };
  </script>
  