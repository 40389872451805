<template>

    <b-container class="content">

        <model-viewer
          alt="house" 
          :src="$utils.state.modelDataUrl" 
          ar 
          ar-modes="scene-viewer quick-look"
          shadow-intensity="1" 
          camera-controls 
          touch-action="pan-y"
          style="width: 300px; 
          height: 300px;">
        <!-- <button slot="hotspot" data-position="0.1m 0.2m -0.3m" data-normal="0.1 0.2 -0.3">Druk hier</button> -->
        </model-viewer>
        
    <button @click="$utils.uploadModel(scene)">Upload Model</button>
    
    <div>{{ this.$utils.state.uploadStatus }}</div>
     
    <modelrow v-if="this.$utils.state.userModelUrl != ''" 
      :model-src= "this.$utils.state.userModelUrl"
      :background-color="'#F5F5F5'"
      :qr-code-size="150"
      :short-code="this.$utils.state.shortCode">
    </modelrow>
    
    </b-container>
    
    </template>
    
    <script>
    import '@google/model-viewer';
    import VueQRCodeComponent from 'vue-qrcode-component'
    import ModelRow from './ModelRow.vue';
    import * as THREE from 'three';
    import { SVGLoader } from 'three/addons/loaders/SVGLoader.js';

 //   import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
 //import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
 import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

    export default {
      name: 'svg_experiment',
      data: function () {
        return{
            isAndroid: false,
            isIPhone: false,
            isMobile: false,
            scene: undefined,
            timer:null,
            meshScale: 0.01,
            svgURL : `/assets/amsterdam.svg`,
            texture:'/images/gradient.webp',
            //texture_repeat: 0.015,
            texture_repeat: 0.001,
            extrudeSettings: {
                        steps: 1,
                        depth: 40,
                        bevelEnabled: true,
                        bevelThickness: 10,
                        bevelSize: 7,
                        bevelOffset: 0,
                        bevelSegments: 10
            },
            // extrudeSettings: {
            //             steps: 1,
            //             depth: 5,
            //             bevelEnabled: true,
            //             bevelThickness: 0.3,
            //             bevelSize: 0.2,
            //             bevelOffset: 0,
            //             bevelSegments: 3
            // },
            gltfExporterOptions:{
                binary: true
            }
            
        
        }
      },
      computed:{
      },
      watch: {
      },
      created:function(){
      },
      mounted:function(){
        this.$utils.siteLog("Svg view");
        this.checkDevice();
        window.addEventListener('resize', this.checkDevice); 
        this.scene = new THREE.Scene();
        this.scene.name = "svg scene";
        this.loadSVGAndExtrude();
      },
     methods: {
        clearScene(){
          for( var i = this.scene.children.length - 1; i >= 0; i--) { 
            const obj = this.scene.children[i];
            this.scene.remove(obj); 
          }
        },
        checkDevice() {
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            this.isAndroid = /Android/.test(navigator.userAgent);
            this.isIPhone = /iPhone/.test(navigator.userAgent);
        },
        loadSVGAndExtrude() {
            const loader = new SVGLoader();

            const that = this;

            const textureLoader = new THREE.TextureLoader();

            textureLoader.load(this.texture, (texture) => {
                //texture.wrapS = THREE.RepeatWrapping;
                //texture.wrapT = THREE.RepeatWrapping;
                texture.repeat.set(this.texture_repeat, this.texture_repeat);
                const material = new THREE.MeshStandardMaterial({ 
                map: texture,
                roughness: 0.2,
                metalness: 0.8
                });

                var geometries = [];

                loader.load(this.svgURL, (data) => {
                    const paths = data.paths;
                    
                    paths.forEach((path) => {
                        const shapes = path.toShapes(true);
                        shapes.forEach((shape) => {
                            const geometry = new THREE.ExtrudeGeometry(shape, this.extrudeSettings);
                            geometries.push(geometry);
                            geometry.rotateX(Math.PI);   
                        });
                        
                    });

                    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries, false);
                    const mesh = new THREE.Mesh(mergedGeometry, material);
                    mesh.scale.set(this.meshScale, this.meshScale, this.meshScale);
                    this.scene.add(mesh);
                    that.$utils.sceneToSrc(this.scene);
                });         
            });
        }
     },
      components: {
        'qr-code': VueQRCodeComponent,
        'modelrow': ModelRow
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.checkDevice);
      }
    }
    </script>
    
    <style scoped>

    .content{
      text-align: left;
      font-family: AvenirMedium;
      font-size: 20px;  ;  
    }
      
    .row-item{
      font-size: 16px;
      padding: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    
    </style>