<template>

<b-container class="content">

  <p>This is a page to test things</p>
      <a href="https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/models/Puccini/Puccinimethode - fietsnietje_maquette.usdz" rel="ar" title="Bekijk in AR">Bekijk in AR</a>
    
      <model-viewer
      :src="src_glb"
      :ios-src="src_usdz"
      alt="Een 3D-model"
      ar
      ar-modes="scene-viewer quick-look"
      shadow-intensity="1"
      camera-controls
      touch-action="pan-y"
      style="width: 300px; height: 300px;">
    </model-viewer>
    


</b-container>





</template>
    
    
<script>
        
    export default {
      name: 'Test',
      data: function () {
        return {
          src_glb: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/models/Puccini/Puccinimethode - fietsnietje_maquette.glb",
          src_usdz: "https://dupdatatavjw5bpo7yfmc.blob.core.windows.net/models/Puccini/Puccinimethode - fietsnietje_maquette.usdz"
        };
      },
      computed:{    
      },
      watch: {
      },
      created:function(){    
      },
      mounted:function(){
       // this.$utils.siteLog('button/download/samplemodel')
      },
     methods: {




     },
      components: {
      },
      beforeDestroy() {
      }
    }
</script>
    
<style scoped>

</style>
    
    