<template>

<b-container class="content">


  <div class="header">
  
    <div class="imgtext">
      <h1 class="fronttext">Digital Urban Planning Lab</h1>
  
      <p class="fronttext">
        In the Digital Urban Planning Lab we explore new technologies and methods for urban planning. In this way, we seek solutions to the challenges faced by growing cities, such as the housing shortage and climate change. We do this with quantitative research, such as innovative data models. And with qualitative research, in which we involve residents.
      </p>

    </div>

  
  <div class="main-image-container">
    <img src="/images/art-impression-blend1.png" class="main-image" />
  </div>
</div>

  <b-row>    
      <b-col class="row-item" >
        <h2>Digital Participation in South East</h2>
        <div>
  For the <a href="https://zoiszuidoost.nl/zo/" target="_blank">Southeast Master Plan</a>, we are investigating how we can involve young people in the future of their district. We do this with new technologies, such as interactive 3D visualizations and augmented reality. This way, residents can walk through their future neighborhood.
</div>  
      </b-col>
  </b-row>

  <modelrow :title="'AR Maquette'"
                 :description="'This demo showcases how a new construction project can be presented as an AR model, allowing community stakeholders, the residents of the neighborhood, to actively participate in the project\'s development. By using augmented reality technology, residents can visualize and interact with the proposed plans, providing valuable input and feedback to shape the future of their community. '"
                 :model-src="'maquette.glb'"
                 :background-color="'#F5F5F5'"
                 :qr-code-size="150">
  </modelrow>

  <modelrow :title="'Outdoor AR Exploration'"
                 :description="'This demo illustrates how a new construction project can be showcased as an AR model, enabling users to explore the exterior of the building by walking around it. With augmented reality technology, users can view the proposed development from various angles and distances, providing a realistic sense of its presence in the environment. This interactive experience empowers community stakeholders, including neighborhood residents, to actively engage with the project and offer valuable insights to enhance its design and integration within the surrounding area.'"
                 :model-src="'gazebo2.glb'"
                 background-color="#ffffff"
                 :qr-code-size="150">
  </modelrow>

  <h2>Team</h2>
  <b-row class="team">

        <b-row class="teamrow" v-for="item in theteam" v-bind:key="item.name">                    
            <b-col class="avatar-col">            
            <img v-bind:src="item.image" alt="" class="avatar">
          </b-col>
          <b-col class="team-col">
            <strong>{{item.name}}</strong>
            <div>{{item.title}}</div>
          </b-col>
        </b-row>
  </b-row>

</b-container>

</template>

<script>

import team from '@/assets/team.json'
import '@google/model-viewer';
import VueQRCodeComponent from 'vue-qrcode-component'
import ModelRow from './ModelRow.vue';

export default {
  name: 'Home',
  data: function () {
    return{
      isAndroid: false,
      isIPhone: false,
      isMobile: false,
      theteam:team,
      modelDataUrl: ''
    }
  },
  computed:{    
  },
  watch: {
  },
  created:function(){    
  },
  mounted:function(){
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice); 
    const modelViewer = this.$refs.androidmodel;
  },
 methods: {
  checkDevice() {
      // Controleer of het een mobiel apparaat is (bijv. iPhone)
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      this.isAndroid = /Android/.test(navigator.userAgent);
      this.isIPhone = /iPhone/.test(navigator.userAgent);
    }
 },
  components: {
    'qr-code': VueQRCodeComponent,
    'modelrow': ModelRow
  },
  beforeDestroy() {
    // Verwijder de event listener bij het vernietigen van de component
    window.removeEventListener('resize', this.checkDevice);
  },
}
</script>

<style scoped>

a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

h1{
  margin-left: 50px;
  margin-right: 50px;
  font-family: AvenirBold;
  font-size: 56px;  
}

p{
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 40px;
  margin-bottom: 40px;
}



h1.fronttext {
  top: 12px;
  left: 20px;
  z-index: 2;
  font-size: 2.5rem;
}

p.fronttext {
  top: 58px;
  left: 20px;
  z-index: 1;
  margin-top: 20px;
}

.imgtext{
  width: 100%;
  position: absolute;
  background-color: rgba(105, 28, 28, 0.14);
  z-index: 1;
  height:580px;
}

@media (max-width: 768px) {
  h1{
    margin-left: 20px;
    margin-right: 20px;
  }

  p{
    margin-left: 20px;
    margin-right: 20px;
  }

  p.fronttext {
    top: 100px;
  }

}


@media (min-width: 576px){
  .imgtext{
    height:405px;
  }
}

@media (min-width: 768px){
  .imgtext{
    height:305px;
  }
}

@media (min-width: 992px){
  .imgtext{
    height:245px;
  }
}

@media (min-width: 1200px){
  .imgtext{
    height:215px;
  }
}



.fronttext{
color:#fff;
}

h2{
  font-family: AvenirBold;
   font-size: 24px;
}



.content{
  text-align: left;
  font-family: AvenirMedium;
  font-size: 20px;  ;  
}




.header {
  position: relative;
  width: 100%;
  height: auto;
}

.main-image-container {
  /* position: relative; */
  width: 100%; /* De breedte van de container */
  height: 740px; /* De hoogte van de container */
  position: relative;
  overflow: hidden; /* Zorgen dat delen van de afbeelding die buiten de container vallen, worden verborgen */
}

.main-image {
  /* width: 100%;
  height: auto; */

  width: 100%; 
  height: 100%;
  object-fit: cover; /* Past de afbeelding aan de container aan zonder uit te rekken */
  object-position: center; /* Centreert de afbeelding binnen de container */
}

.fronttext {
  position: absolute;
  color: white;
  padding: 5px;
}




.qr-image{
  max-width: 200px;
}

.buttons{
 /* background-color: #ff9100; */
 padding: 40px; 
}

.row-item{
  font-size: 16px;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
}

.linkcard{
  width: 50%;
  max-width: 50%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.col-center{
  padding-top: 20px;
  text-align: center;
}

.avatar{
   border-radius: 50%;
   width: 44px;
}

.avatar-col{  
  flex: 0 0 48px !important;
  min-width: 48px;
  padding: 0px;
}

.team-col{  
  overflow: hidden;
}

.team{
  font-size: 16px;
  font-family: AvenirRegular;  
}

.teamrow{
width: 300px;
  margin-top:10px;
  margin-bottom:10px;
  margin-left: 40px;
}

.email, .links a, .links a:visited{
  color: rgb(118, 142, 158);  
}

.button-link{
  margin-top:20px;
  margin-bottom: 20px;
  background-color: #01489A;
}

.spacer{
margin-top:10px;
}

</style>

