<template>

    <b-container class="content">


      <ul v-for="(directory, index) in directoryData.directories" :key="index" class="no-bullets">
        <h1>{{ directory.name }}</h1>

          <li v-for="(file, fileIndex) in directory.files" :key="fileIndex">
          
            <p>
              <b-button variant="info" @click="show360(directory.name, file.fileName, file.shortCode)">  
              {{ $utils.extractFileName(file.fileName) }}
              </b-button>
              <b-button variant="info" @click="showQR(fileIndex)">QR</b-button>
            </p>

            <qr-code v-if="currentIndex == fileIndex"  :text="geturl(file.shortCode)" :size="300" ref="qrCode"></qr-code>

          </li> 
        </ul>

    </b-container>
    
    </template>
    
    <script>
    
    import VueQRCodeComponent from 'vue-qrcode-component'


    export default {
      name: 'Models',
      data: function () {
        return{
          isAndroid: false,
          isIPhone: false,
          isMobile: false,
          directoryData: Object,
          currentIndex: 0

        }
      },
      computed:{
        showModelDialog() {   
            return this.currentDirectory != '' && this.currentFile != '' && this.currentShortCode != '';
          } 
      },
      watch: {
      },
      created:function(){    
      },
      mounted:function(){
        this.checkDevice();
        window.addEventListener('resize', this.checkDevice); 
        this.getDataFiles();
      },
     methods: {
      showQR(index){
        this.currentIndex = index;
      },
        show360(directory, file, short){    
          window.location = `${window.location.origin}/#/360/${short}`;
        },
        geturl(filename){
            return `${window.location.origin}/#/360/${filename}`;
        },
        async getDataFiles() {
          try {
            const response = await fetch(`${this.$store.apiUrl}/GetData`);
            const data = await response.json();

            this.directoryData = data;
            this.$store.baseUrl = data.baseUrl;
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        },
        checkDevice() {
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            this.isAndroid = /Android/.test(navigator.userAgent);
            this.isIPhone = /iPhone/.test(navigator.userAgent);
        },
        showModel(directory, file, shortCode){
          this.currentDirectory = directory;
          this.currentFile = file;
          this.currentShortCode = shortCode;

          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });

        }
     },
      components: {
        'qr-code': VueQRCodeComponent
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.checkDevice);
      }
    }
    </script>
    
    <style scoped>

    .content{
      text-align: left;
      font-family: AvenirMedium;
      
    }

    h1{
      font-size: 24px;
    }

    li{
      padding: 2px;
      padding-left: 10px;
    }

    .no-bullets {
      list-style-type: none; /* Verwijder de standaard bullets */
      padding: 0; /* Verwijder standaard padding */
    }
    .no-bullets li {
      margin: 5px 0; /* Optioneel: stel een marge in voor de lijstitems */
    }
      
    .row-item{
      font-size: 16px;
      padding: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .modelrow{
      margin-bottom: 100px;
    }

    button{
      margin:20px;
    }
    </style>
    
    