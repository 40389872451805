import Vue from 'vue';
//import store from './store';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

import { uuid } from 'vue-uuid';

export default class Utils {

    store = Vue.prototype.$store;
    bvToast = null;
  
    constructor() {

      this.getClient();

      this.uploadModel = this.uploadModel.bind(this);

      this.state = Vue.observable({
        modelDataUrl : "",
        userModelUrl : "",
        uploadStatus : "",
        shortCode : ''
      });
    }

    addsitelog = true;

    gltfExporterOptions = {
        binary: true
    };

    resetState() {
      this.state.modelDataUrl = "";
      this.state.userModelUrl = "";
      this.state.uploadStatus = "";
      this.state.shortCode = '';
    }

    sceneToSrc(scene){

        const gltfExporter = new GLTFExporter();

        gltfExporter.parse(scene, (glb) => {
            const blob = new Blob([glb], { type: 'model/gltf-binary' });
            const url = URL.createObjectURL(blob);
            this.state.modelDataUrl = url;
        }, undefined, this.gltfExporterOptions);
    }

    async uploadModel(scene) {   

        this.state.uploadStatus = "Preparing upload...";

        const gltfExporter = new GLTFExporter();
        gltfExporter.parse(scene, async (glb) => {
          try {
            const blob = new Blob([glb], { type: 'model/gltf-binary' });

            const formData = new FormData();
            formData.append('file', blob);

            this.state.uploadStatus = "uploading...";

            var url = `${this.store.apiUrl}/saveusermodel`;

            const clientId = this.store.clientId;
            if(clientId) url+= `/${clientId}`;

            const response = await fetch(url, {
              method: 'POST',
              body: formData
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            this.state.uploadStatus = "uploaded";

            console.log("file uploaded");

            setTimeout(() => {
              this.uploadStatus = "";
            }, 3000); 

            this.state.userModelUrl = `${data.blobUrl}?timestamp=${Date.now()}` ;
            this.state.shortCode = data.shortCode;
           // localStorage.setItem('clientId', data.clientId);

          } catch (error) {
            console.error('Fetch Error:', error);
          }
        }, undefined, this.gltfExporterOptions );

      }

      async uploadMaquette(scene, filename) {   

        let splitted = filename.split('.');
        filename = `${splitted[0]}_maquette.${splitted[1]}`;

        this.state.uploadStatus = "Preparing upload...";

        const gltfExporter = new GLTFExporter();
        gltfExporter.parse(scene, async (glb) => {
          try {
            const blob = new Blob([glb], { type: 'model/gltf-binary' });

            const formData = new FormData();
            formData.append('file', blob);
            formData.append('filename', filename); 

            this.state.uploadStatus = "Uploading maquette...";

            var url = `${this.store.apiUrl}/savemaquettemodel`;

            const response = await fetch(url, {
              method: 'POST',
              body: formData
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            this.state.uploadStatus = "Maquette uploaded.";
            setTimeout(() => {
              this.uploadStatus = "";
            }, 3000); 

            this.state.userModelUrl = `${data.blobUrl}?timestamp=${Date.now()}` ;
            this.state.shortCode = data.shortCode;
        //    localStorage.setItem('clientId', data.clientId);

          } catch (error) {
            console.error('Fetch Error:', error);
          }
        }, undefined, this.gltfExporterOptions );

      }

      async uploadUSDZ(blob, filename) {   

        this.state.uploadStatus = "Preparing upload...";

        const formData = new FormData();
        formData.append('file', blob);
        formData.append('filename', filename); 

        this.state.uploadStatus = "Uploading USDZ...";

        var url = `${this.store.apiUrl}/savemaquettemodel`;

        const response = await fetch(url, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        this.state.uploadStatus = "Uploaded USDZ";
        setTimeout(() => {
          this.uploadStatus = "";
        }, 3000); 

        this.state.userModelUrl = `${data.blobUrl}?timestamp=${Date.now()}` ;
        this.state.shortCode = data.shortCode;
        //localStorage.setItem('clientId', data.clientId);


      }




      async getClient()
      {
        try 
        {
            let url = `${this.store.apiUrl}/getclient`;

            const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                clientId: this.store.clientId,
                isMobile : /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
                userAgent: navigator.userAgent
            })
          });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();

            if(data.clientId != undefined ){
              localStorage.setItem('clientId', data.clientId);
              this.store.state.isAdmin = data.role == "admin";
            }
        } catch (error) {
            console.error('Error fetching client data:', error);
            throw error;
        }
    }

    async siteLog(page, id, project) {

        if(this.addsitelog == false) return;
        
        try {

            const response = await fetch(`${this.store.apiUrl}/sitelog`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  clientid: this.store.clientId,
                  page: page,
                  project: project,
                  id : id
              })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    async AddProjectToShortUrl(shortcode, project) {
      
      try {

          const response = await fetch(`${this.store.apiUrl}/UpdateShorturl`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                clientId: this.store.clientId,
                shortCode: shortcode,
                project : project
            })
          });

          return response.ok;
          
      } catch (error) {
          console.error('Error:', error);
          return
      }
  }

    extractFileName(fileUrl) {
      return decodeURIComponent(fileUrl.split('/').pop());
    }

    showToast(message, title, variant) {
      this.bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }

  }