import Vue from 'vue';

export default class Store
{
  isDebug = true;
  baseUrl = '';
  //apiUrl = "http://localhost:7071/api";
  apiUrl = "https://dup-backend.azurewebsites.net/api";

  state = null;

  constructor()
  {
    this.state = Vue.observable({
      projects : null,
      isAdmin:false
    });

    this.fetchProjects();

  }

  async fetchProjects() 
  {
    if (!this.state.projects) {
      try {
        const response = await fetch(`${this.apiUrl}/GetProjects`);
        const data = await response.json();
        this.state.projects = data;

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    //console.log(this.state.projects);
    return this.state.projects;
  }

  get projects() {
    return this.fetchProjects();
  }

  get clientId(){
    return localStorage.getItem('clientId');
  }
  
}