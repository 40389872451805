import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import Store from './store';
import Utils from './utils';
import VueClipboard from 'vue-clipboard2';

 Vue.config.productionTip = false;

 Vue.use(VueClipboard);  

Vue.prototype.$store = new Store();
Vue.prototype.$utils = new Utils();

router.beforeEach((to, from, next) => {
  // Reset de state voordat je naar een nieuwe pagina gaat
  Vue.prototype.$utils.resetState();
  next();
});

var vueinstance = new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app');

vueinstance.$utils.bvToast = vueinstance.$root.$bvToast;