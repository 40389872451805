<template>

<b-container class="content">

<p>
  For this project, we delved into exploring the potential of augmented reality (AR). Our initial investigation focused on developing a native app using Unity, known for its robust AR support. 
  However, we encountered challenges in the development process, such as deploying the app onto test devices and automating build and deployment procedures. 
  Additionally, requiring users to install an app posed a barrier, prompting us to explore alternatives like Apple App Clip and Google Instant View. 
  While intriguing, these options proved cumbersome, particularly with Unity's limitations on creating small app builds. 
  We shifted our attention to Google Flutter, an open-source platform for building native cross-platform apps, which showed promise in this regard.
</p>
<p>Further exploration led us to web frameworks like A-Frame.js and AR.js, as well as various commercial web AR services. 
  However, some proved ineffective or came with hefty price tags. 
  Upon deeper investigation, we discovered that mobile browsers support AR through Apple Quick Look AR and Google Scene Viewer. 
  To simplify utilization, Google developed an open-source framework called Model-Viewer. 
  Leveraging this, we developed a proof of concept enabling easy display and placement of GLTF/GLB 3D models in AR within supported browsers. 
  Additionally, it facilitates capturing photos or videos within the AR experience. 
  </p>
  <p>
Below is an example showcasing the Bloomberg logo placed in AR, with Roland Persaud standing in front, highlighting the attention to detail as the logo is positioned behind him, thus demonstrating the immersive capabilities of the framework.
  </p>



  <img src="images/bloomberg.jpg" alt="">

</b-container>
</template>
        

<script>
export default {
  name: 'Findings',
  data: function () {
    return{
      name: "Findings"
    }
  },
  created:function(){
    
  },
  mounted:function(){        
  },
  methods: {        
  }
}

</script>


        
<style scoped>

.content{
  text-align: left;
  margin-left: 200px;
  margin-right: 200px;
}

/* h1{  
  font-family: AvenirBold;
  font-size: 56px;
} */

h2{
    font-size: 12px;    
    margin-bottom: 20px;
}


</style>
    