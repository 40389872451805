<template>
    <b-row :style="{ backgroundColor: backgroundColor }">
      <b-col class="row-item">
        <h2>{{ title }}</h2>
        <div>{{ description }}</div>
        <div>{{ $utils.state.uploadStatus }}</div>
  
        <b-row>

          <b-col class="sub-row-item">
            <!-- <div v-if="$store.isDebug" >
              <div>{{ modelAssetURL }}</div>
              <div>{{ $props.iosSrc }}</div>
            </div> -->

            <model-viewer alt="house" 
                          :src="modelAssetURL" 
                          :ios-src="$props.iosSrc"
                          ar 
                          ar-modes="scene-viewer quick-look"
                          shadow-intensity="10" 
                          camera-controls 
                          touch-action="pan-y"
                          style="width: 300px; height: 300px;">
            </model-viewer>
          </b-col>

          <b-col v-if="!isMobile" class="sub-row-item">
            <qr-code :text="modelViewerURL" :size="qrCodeSize"></qr-code>
            <a v-if="$store.isDebug && $store.state.isAdmin" :href="`${modelViewerURL}`">{{ modelViewerURL }}</a>
          </b-col>


          <b-col  class="sub-row-item">

            <!-- <b-button v-if="!isMobile && $props.addDownload"  v-bind="{tag: 'a', href: `${modelAssetURL}`, download:'model.glb'}"
              variant="primary">Download model</b-button> -->

            <b-button v-if="$store.state.isAdmin" v-bind="{tag: 'a', href: `${modelAssetURL}`, download:'model.glb'}" variant="primary">Download model</b-button>
              <p></p>

              <b-button v-if="$store.state.isAdmin" @click="convertToMaquette" variant="primary">Convert to maquette</b-button>
              <p></p>

              <b-button v-if="$store.state.isAdmin" @click="convertToUSDZ" variant="primary">Convert to USDZ</b-button>
              <p></p>
            
            <p></p>
            <!-- <div>{{ $props.modelSrc }}</div>
            <div>{{ $props.iosSrc }}</div> -->
            <a v-if="$props.iosSrc" :href="`${$props.baseUrl}/${$props.iosSrc}`" rel="ar" >Bekijk op een iPhone</a>
   

            <p v-if="$store.state.isAdmin">
              <b-dropdown :text="currentProject || 'Selecteer een project...'" class="custom-dropdown" variant="primary">
              <b-dropdown-item 
                v-for="(project, index) in this.$store.state.projects"
                :key="index" 
                @click="updateProject(shortCode, project.PartitionKey)">
                {{ project.PartitionKey }}
              </b-dropdown-item>
            </b-dropdown>
            </p>




          </b-col>

        </b-row>  
      </b-col>


    </b-row>
  </template>
  
  <script>

import VueQRCodeComponent from 'vue-qrcode-component'
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter.js';

  export default {
    data: function () {
      return{
        isMobile: false,
        newShortCode: "",
        currentProject:""
      }
    },
    props: {
      title: {
        type: String,
        default: ""
      },
      description: {
        type: String,
        default: ""
      },
      modelSrc: {
        type: String,
        default: ""
      },
      iosSrc: {
        type: String,
        default: null
      },
      baseUrl: {
        type: String,
        default: ""
      },
      shortCode: {
        type: String,
        default: ""
      },
      project: {
        type: String,
        default: ""
      },
      backgroundColor: {
        type: String,
        default: ""
      },
      qrCodeSize: {
        type: Number,
        default: 800
      },
      addConvert: {
        type: Boolean,
        default: false
      },      
      addDownload: {
        type: Boolean,
        default: false
      }
      
    },
    mounted:function(){
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      this.currentProject = this.$props.project;
    },
    methods: {
      convertToMaquette(){
        var scene = new THREE.Scene();
        const loader = new GLTFLoader();
        
        loader.load(this.modelAssetURL,(gltf) => {

          // Create a box around the GLB object
          const box = new THREE.Box3().setFromObject(gltf.scene);
          var boxSize = box.getSize(new THREE.Vector3()).length();
          var boxCenter = box.getCenter(new THREE.Vector3());

          // Create an invisible box mesh to surround the GLB object
          const geometry = new THREE.BoxGeometry(boxSize, boxSize, boxSize);
          const material = new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true, transparent: true, opacity: 0.1 });
          
          const invisibleBox = new THREE.Mesh(geometry, material);
          invisibleBox.name = "invisiblebox";
          invisibleBox.position.copy(boxCenter);

          // Add the GLB object as a child of the invisible box
          invisibleBox.add(gltf.scene);
          gltf.scene.position.set(-boxCenter.x, -boxCenter.y, -boxCenter.z);

          // Add the invisible box to the scene
          scene.add(invisibleBox);

          //now resize and upload  
          var sceneMaquette = new THREE.Scene();
          var group = new THREE.Group();

          scene.children.forEach((child) => {
                if (!child.isLight && !child.isCamera && child.name != "floor") {

                  if (child.name == "invisiblebox") 
                  {
                    let parentPosition = child.position.clone();
                    child = child.children[0];

                    const clonedChild = child.clone();
                    clonedChild.position.add(parentPosition);
                    group.add(clonedChild);

                  }
                  else{
                    const clonedChild = child.clone();
                    group.add(clonedChild);
                  }
                  
                }
            });

          var scale = 1/ boxSize;
          group.scale.set(scale, scale, scale);

          sceneMaquette.add(group);

          console.log(sceneMaquette);

          var splitted = this.modelAssetURL.split('/models/');
          var filename = splitted[1];

          this.$utils.uploadMaquette(sceneMaquette, filename);

        });
      },
      convertToUSDZ() {
        const loader = new GLTFLoader();
        loader.load(this.modelAssetURL,(gltf) => {
          const exporter = new USDZExporter();
          exporter.parse(gltf.scene, (usdzArrayBuffer) => {
            const blob = new Blob([usdzArrayBuffer], { type: 'model/vnd.usdz+zip' });

            var splitted = this.modelAssetURL.split('/models/');
            var filename = splitted[1];

            filename = filename.replace(/\.glb$/i, '.usdz');

            this.$utils.uploadUSDZ(blob, filename);
            },
            (error) => {
              console.error('Error during conversion:', error);
            }
        );

        },
        undefined,
        (error) => {
          console.error('Er is een fout opgetreden bij het laden van het GLB-model:', error);
        }
      );
      },
      async getShortcode() {

        const isUrl  = this.isUrlValid(this.modelAssetURL);
        const assetUrl = isUrl ? this.modelAssetURL : `${window.location.origin}/${this.modelAssetURL}`;
               
        fetch(`${this.$store.apiUrl}/getshortcode`, {
                method: 'POST',
                body: JSON.stringify({ url: assetUrl}),
                headers: {
                    'Content-Type': 'application/json'
                }
                })
                .then(response => {
                    if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
                })
                .then(data => {
                  console.log(data);
                    this.newShortCode = data.shortCode;
                    this.currentProject = data.project;
                })
                .catch(error => {
                    console.error('Fetch Error:', error);
            });
      },
      updateProject(shortcode, project){
        if(this.currentProject != project){
        this.currentProject = project;
          //console.log(`${shortcode}/${project}`);
          this.$utils.AddProjectToShortUrl(shortcode, project);
        }
      },
      isUrlValid(url) {
          var pattern = /^(http|https):\/\//i;
          return pattern.test(url);
      }
    },
    computed: {
      modelAssetURL() {
        if(this.isUrlValid(this.modelSrc)) return this.modelSrc;
        else if(this.baseUrl != "") return `${this.baseUrl}/${this.modelSrc}`;
        else return `assets/${this.modelSrc}`;
      },
      modelViewerURL() {

        if(this.shortCode == "") this.getShortcode();
        else this.newShortCode = this.shortCode;
        return `${window.location.origin}/#/ar/${this.newShortCode}`;
      // return `https://dup.rnd.amsterdam.nl/#/ar/${this.newShortCode}?rnd=${new Date().getTime()}`;
    }
  },
  watch: {
    project(newValue) {
      // Update currentProject wanneer de project prop verandert
      this.currentProject = newValue;
    }
  },
    components: {
      'qr-code': VueQRCodeComponent
    }
  }
  </script>

<style scoped>

.row-item{
  font-size: 16px;
  padding: 40px;
  margin:auto;
}

.sub-row-item{
  margin-top: auto;
  margin-bottom: auto;
}

.custom-dropdown .dropdown-toggle {
  background-color: #F0F0F0 !important; /* Set the background color of the button */
  color: #0000FF !important; /* Set the text color of the button */
  border: 1px solid #0000FF !important; /* Optional: border color */
}

.custom-dropdown .dropdown-toggle:hover {
  background-color: #C0C0C0 !important; /* Set the background color on hover */
  color: #FFFFFF !important; /* Set the text color on hover */
}

.custom-dropdown .dropdown-menu {
  background-color: #F0F0F0 !important; /* Set the background color of the dropdown menu */
}

.custom-dropdown .dropdown-item {
  color: #0000FF !important; /* Set the text color of the items */
}

.custom-dropdown .dropdown-item:hover {
  background-color: #C0C0C0 !important; /* Set the background color on hover */
  color: #FFFFFF !important; /* Set the text color on hover */
}


</style>