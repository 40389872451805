import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Experiments from '../views/Experiments.vue'
import Models from '../views/Models.vue'
import Bevindingen from '../views/Findings.vue'
import ModelViewer from '../views/ModelViewer.vue'
import Groove from '../views/Groove.vue'
import Svg from '../views/Svg.vue'
import Design from '../views/Design.vue'
import Test from '../views/Test.vue'
//import Tile3D from '../views/Tile.vue'
import Pano from '../views/360.vue'
import PanoFiles from '../views/360files.vue'
import Videos from '../views/Videos.vue'
// import Wayfind from '../views/Wayfind.vue'
import QR from '../views/QR.vue'
import Metrics from '../views/Metrics.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Digital Urban Planning Amsterdam / R&D',
    component: Home
  },
  {
    path: '/design',
    name: 'Design',
    component: Design
  },
  {
    path: '/models',
    name: 'Models',
    component: Models
  },
  {
    path: '/experiments',
    name: 'Experiments',
    component: Experiments
  },
  {
    path: '/findings',
    name: 'Findings',
    component: Bevindingen
  },
  {
    path: '/ar/:shortCode',
    name: 'ModelViewer',
    component: ModelViewer,
    props: true
  },
  {
    path: '/groove',
    name: 'Groove',
    component: Groove
  },
  {
    path: '/svg',
    name: 'SVG',
    component: Svg
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  // {
  //   path: '/3dtile',
  //   name: '3DTile test',
  //   component: Tile3D
  // },
  {
    path: '/360files',
    name: '360 files',
    component: PanoFiles
  },
  {
    path: '/360/:shortcode',
    name: '360 file demo',
    component: Pano
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  // {
  //   path: '/wayfind',
  //   name: 'Wayfind',
  //   component: Wayfind
  // },
  {
    path: '/qr',
    name: 'QR',
    component: QR
  },
  {
    path: '/metrics',
    name: 'Metrics',
    component: Metrics
  }

]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
})

export default router
