<template>

    <b-container class="content">

        <input v-model="message" placeholder="Add text" @input="handleInputChange">

        <div><color-picker v-model="selectedColor" /></div>

        <model-viewer v-if="message"
          alt="house" 
          :src="this.$utils.state.modelDataUrl" 
          ar 
          ar-modes="scene-viewer quick-look"
          shadow-intensity="1" 
          camera-controls 
          touch-action="pan-y"
          style="width: 300px; 
          height: 300px;">
        </model-viewer>

    <button @click="$utils.uploadModel(scene)">Upload Model</button>
     <div>{{ $utils.state.uploadStatus }}</div>
     
    <modelrow v-if="this.$utils.state.userModelUrl != ''" 
    
      :model-src= "this.$utils.state.userModelUrl"
      :background-color="'#F5F5F5'"
      :qr-code-size="150"
      :short-code="this.$utils.state.shortCode">
    </modelrow>
    
    </b-container>
    
    </template>
    
    <script>
    import '@google/model-viewer';
    import VueQRCodeComponent from 'vue-qrcode-component'
    import ModelRow from './ModelRow.vue';
    import * as THREE from 'three';
    import { FontLoader } from 'three/addons/loaders/FontLoader.js';
    import { Compact } from 'vue-color';
    
    export default {
      name: 'Experiments',
      data: function () {
        return{
          isAndroid: false,
          isIPhone: false,
          isMobile: false,
          message: "Test",
          font: undefined,
          scene: undefined,
          timer:null,
          selectedColor: '#ff0000',
          meshMaterial: new THREE.MeshStandardMaterial({ metalness: 0.5, roughness: 0 })
        }
      },
      computed:{    
      },
      watch: {
        selectedColor(newValue) {
          var r = newValue.rgba.r/255;
          var g = newValue.rgba.g/255;
          var b = newValue.rgba.b/255;
          this.meshMaterial.color = new THREE.Color(r,g,b);
          this.updateText();
        }
      },
      created:function(){    
      },
      mounted:function(){
        this.$utils.siteLog("Experiments view");
        this.checkDevice();
        window.addEventListener('resize', this.checkDevice); 
        this.scene = new THREE.Scene();
        this.loadFont();
        //this.generateCube();
      },
     methods: {
        handleInputChange() {
          this.updateText();
        },
        loadFont(){
          const that = this;
          const loader = new FontLoader();
          loader.load('/fonts/gentilis_bold.typeface.json', function(font)
          {
              that.font = font;
              that.updateText();
          });
        },
        clearScene(){
          for( var i = this.scene.children.length - 1; i >= 0; i--) { 
            const obj = this.scene.children[i];
            this.scene.remove(obj); 
          }
        },
        updateText(){
          if(this.font == undefined) return;
          if(this.message == "") return;

          const shapes = this.font.generateShapes( this.message, 1 );
          const extrudeSettings = {
            steps: 4,
            depth: 0.3,
            bevelEnabled: true,
            bevelThickness: 0.05,
            bevelSize: 0.05,
            bevelSegments: 3
          };     
          const geometry = new THREE.ExtrudeGeometry(shapes, extrudeSettings);
          const mesh = new THREE.Mesh(geometry, this.meshMaterial);

          this.clearScene();

          this.scene.add(mesh);
          this.$utils.sceneToSrc(this.scene);
        },
//         async uploadModel() {
          
//           this.uploadStatus = "Preparing upload...";
//           const gltfExporter = new GLTFExporter();
//           gltfExporter.parse(this.scene, async (gltf) => {
//             try {
//               const json = JSON.stringify(gltf);
//               const blob = new Blob([json], { type: 'model/gltf+json' });
//               const formData = new FormData();
//               formData.append('file', blob);

//               this.uploadStatus = "uploading...";

//               var url = `${this.$store.apiUrl}/saveusermodel`;

//               const clientId = localStorage.getItem('clientId');
//               if(clientId) url+= `/${clientId}`;

//               const response = await fetch(url, {
//                 method: 'POST',
//                 body: formData
//               });

//               if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`);
//               }

//               const data = await response.json();
//               this.uploadStatus = "uploaded";
//               setTimeout(() => {
//                 this.uploadStatus = "";
//               }, 3000); 

//               this.userModelUrl = `${data.blobUrl}?timestamp=${Date.now()}` ;
//               this.shortCode = data.shortCode;
//               localStorage.setItem('clientId', data.clientId);

//             } catch (error) {
//               console.error('Fetch Error:', error);
//             }
//           });
// },
        checkDevice() {
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            this.isAndroid = /Android/.test(navigator.userAgent);
            this.isIPhone = /iPhone/.test(navigator.userAgent);
        }
     },
      components: {
        'qr-code': VueQRCodeComponent,
        'modelrow': ModelRow,
        'color-picker': Compact
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.checkDevice);
      }
    }
    </script>
    
    <style scoped>

    .content{
      text-align: left;
      font-family: AvenirMedium;
      font-size: 20px;  ;  
    }
      
    .row-item{
      font-size: 16px;
      padding: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    
    </style>
    
    